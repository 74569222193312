import Raven from "raven-js";
import configs from "./utils/configs";

window.dataLayer = window.dataLayer || [];

function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

export default function registerTelemetry(trackedPage, trackedTitle) {
  const sentryDsn = configs.SENTRY_DSN;
  const gaTrackingId = configs.GA_TRACKING_ID;

  if (sentryDsn) {
    console.log("Tracking: Sentry DSN: " + sentryDsn);
    Raven.config(sentryDsn).install();
  }

  if (gaTrackingId) {
    console.log("Tracking: Google Analytics ID: " + gaTrackingId);

    gtag('config', configs.GA_TRACKING_ID, {send_page_view: false, 'page_path': trackedPage});
    gtag('event', 'page_view', {
      page_title: trackedTitle,
      page_location: trackedPage
    })
  }
}
