import appLogo from "../assets/images/app-logo.png";
import companyLogo from "../assets/images/company-logo.png";
import homeHeroBackground from "../assets/images/home-hero-background-unbranded.png";
import sceneEditorLogo from "../assets/images/editor-logo.png";
import { getLocale, getMessage } from "./i18n";

// Read configs from global variable if available, otherwise use the process.env injected from build.
const configs = {};
let isAdmin = false;
[
  "RETICULUM_SERVER",
  "THUMBNAIL_SERVER",
  "CORS_PROXY_SERVER",
  "NON_CORS_PROXY_DOMAINS",
  "SENTRY_DSN",
  "GA_TRACKING_ID",
  "SHORTLINK_DOMAIN",
  "BASE_ASSETS_PATH",
  "UPLOADS_HOST"
].forEach(x => {
  const el = document.querySelector(`meta[name='env:${x.toLowerCase()}']`);
  configs[x] = el ? el.getAttribute("content") : process.env[x];

  if (x === "BASE_ASSETS_PATH" && configs[x]) {
    // eslint-disable-next-line no-undef
    __webpack_public_path__ = configs[x];
  }
});

// Custom clients do not use <meta> tags for passing data, so if thumbnail_server meta tag exists, it is not a custom client
const hasThumbnailServerMetaTag = !!document.querySelector("meta[name='env:thumbnail_server']");
configs.IS_LOCAL_OR_CUSTOM_CLIENT = !hasThumbnailServerMetaTag;

// Also include configs that reticulum injects as a script in the page head.

configs.AVAILABLE_INTEGRATIONS = window.AVAILABLE_INTEGRATIONS || {};

if (process.env.APP_CONFIG) {
  window.APP_CONFIG = process.env.APP_CONFIG;
}

if (window.APP_CONFIG) {
  configs.APP_CONFIG = window.APP_CONFIG;
  const { theme } = configs.APP_CONFIG;
  if (theme) {
    const colorVars = [];
    for (const key in theme) {
      if (!theme.hasOwnProperty(key)) continue;
      colorVars.push(`--${key}: ${theme[key]};`);
    }
    const style = document.createElement("style");
    style.innerHTML = `:root{${colorVars.join("\n")}}`;
    document.head.insertBefore(style, document.head.firstChild);
  }

  if (!configs.APP_CONFIG.features) {
    configs.APP_CONFIG.features = {};
  }
} else {
  configs.APP_CONFIG = {
    features: {}
  };
}

const isLocalDevelopment = process.env.NODE_ENV === "development";

configs.feature = featureName => {
  const value = configs.APP_CONFIG && configs.APP_CONFIG.features && configs.APP_CONFIG.features[featureName];
  if (typeof value === "boolean" || featureName === "enable_spoke") {
    const forceEnableSpoke = featureName === "enable_spoke" && isAdmin;
    return forceEnableSpoke || value;
  } else {
    return value;
  }
};

let localDevImages = {};
if (isLocalDevelopment) {
  localDevImages = {
    logo: appLogo,
    company_logo: companyLogo,
    editor_logo: sceneEditorLogo,
    home_background: homeHeroBackground
  };
}

configs.image = (imageName, cssUrl) => {
  const url =
    (configs.APP_CONFIG && configs.APP_CONFIG.images && configs.APP_CONFIG.images[imageName]) ||
    localDevImages[imageName];
  return url && cssUrl ? `url(${url})` : url;
};

configs.link = (linkName, defaultValue) => {
  return (configs.APP_CONFIG && configs.APP_CONFIG.links && configs.APP_CONFIG.links[linkName]) || defaultValue;
};

configs.setIsAdmin = _isAdmin => {
  isAdmin = _isAdmin;
};
configs.isAdmin = () => isAdmin;

configs.integration = integration => {
  const availableIntegrations = configs.AVAILABLE_INTEGRATIONS;
  // AVAILABLE_INTEGRATIONS has no properties defined on the dev server, but does support all integrations.
  return !availableIntegrations.hasOwnProperty(integration) || availableIntegrations[integration];
};

configs.translation = key => {
  const locale = getLocale();
  const translationsConfig = (configs.APP_CONFIG && configs.APP_CONFIG.translations) || {};

  return (
    (translationsConfig[locale] && translationsConfig[locale][key]) ||
    (translationsConfig.en && translationsConfig.en[key]) ||
    getMessage(key) ||
    ""
  );
};

configs.ui = {
  showStats: true,
  showObjectList: false,
  showPresenceList: true,
  showFavourite: false,
  showShare: true,
  showShareCode: false,
  showShareEmbed: false,
  showUpload: false,
  showRoomInfo: true,
  showExtranousItems: false,
  showHelp: false,
  showSnapInVideo: false,
  showBoothList: true,
  showShareScreen: false,
  showShareCamera: false,
  showMic: false,
  showCreateObject: false,
  showPen: false,
  showCameraCapture: false,
  showThirdPerson: true,
  showChatLog: true,
  showChat: true,
  showEmoji: false,
  showAllMediaSource: false,
  showVote: true,
  showSurvey: true,
  showLivexrExtra: true,
  showLoginWhenPin: false,
  showSelfieCamera: true,
  showEventDialog: true,
  showToolbarControlInstruction: true,
  showViewportControlInstructionButton: true,
  showEventMarquee: true,
  showEventOnLoadingScreen: true,
  showNewsOnLoadingScreen: false,
  showLoadingOnBlackscreen: false,
  showExitBtn: false,
  showVRBtn: false,
  showCustomExitBtn: false,
  showIdentityName: false
}

configs.utils = {
  allowLiveXRIDLogin: true,
  allowChatCommand: false,
  allowCustomAvatar: false,
  allowCreateAvatar: false,
  allowCreateScene: false,
  allowTips: false,
  allowHubsLogin: false,
  allowFindBestRoom: true,
  allowBackToLanding: true,
  allowLobbySpectater: false,
  allowShowWayPoint: true,
  allowFreeze: false,
  allowFullPreferences: false,
  allowChatlog: false,
  allowDescriptionForExtra: true,
  allowJump: true,
  allowFullRoomVoice: false
}

configs.url = {
  pliveLoginPage: `https://meta-messe.azurewebsites.net/m/login`,
  referrerBase: [
    `meta-messe.azurewebsites.net`,
    `meta-messe.com`
  ],
  entrancePage: `https://www.meta-messe.com/ujcZsFi/`,
  privateEntrancePage: `https://www.meta-messe.com/tM7MAZn/`,
  landingPage: `https://www.meta-messe.com`,
  liveXRApiBase: `https://live-xr-api.meta-messe.com`,
  assetBase: `https://meta-messe-prod-cors-proxy.meta-messe.com/https://data.meta-messe.com`
}

configs.ga = {
  pageViewRegistrateMethod: "RoomID"  // Option: RoomID, SceneID
}

configs.app = {
  photoPrefix: "MetaMesse"
}

configs.auth = {
  endpointPath: 'https://cms-data.meta-messe.com',  
  apiPath: 'https://api.meta-messe.com',
  identityPoolId: '',
  region: 'ap-northeast-1',
  bucket: 'cms-data.meta-messe.com',
  accessKey: 'AKIAT4B42PTXPOVRSJVI',
  secretKey: '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',
  userPoolId: 'ap-northeast-1_Gi7UJTAxM',
  userPoolWebClientId: '3n0nct56egi4un5o3b7to54ekl'
}

configs.permissions = [];
// [Role.Guest]: "ゲスト",
// [Role.Admin]: "VRADE事務局",
// [Role.Organizer]: "主催者",
// [Role.Visitor]: "来場者",
// [Role.Speaker]: "登壇者",
// [Role.Exhibitor]: "出展者",
// [Role.Staff]: "スタッフ",
// [Role.Concerned]: "関係者",
// [Role.Cooperator]: "協賛者",

configs.permissions["ゲスト"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: false,
    showShareCamera: false,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}

configs.permissions["事務局"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: true,
    showExtranousItems: false,
    showShareScreen: true,
    showShareCamera: true,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: true
  }
}

configs.permissions["主催者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: true,
    showExtranousItems: false,
    showShareScreen: true,
    showShareCamera: true,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: true
  }
}

configs.permissions["来場者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: false,
    showShareCamera: false,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}

configs.permissions["登壇者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: true,
    showShareCamera: true,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: true
  }
}

configs.permissions["出展者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: true,
    showShareCamera: true,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}

configs.permissions["スタッフ"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: true,
    showShareCamera: true,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}

configs.permissions["関係者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: false,
    showShareCamera: false,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}

configs.permissions["協賛者"] = {
  ui: {
    showPresenceList: true,
    showRoomInfo: false,
    showExtranousItems: false,
    showShareScreen: false,
    showShareCamera: false,
    showMic: true,
    showThirdPerson: true,
    showChat: true,
    showEmoji: false,
    showLivexrExtra: true
  }, 
  utils: {
    allowChatCommand: false,
    allowCustomAvatar: false,
    allowFreeze: false,
    allowFullPreferences: false,
    allowFullRoomVoice: false
  }
}


export default configs;
